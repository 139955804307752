.nav {
  position: relative;

  width: 100vw;
  padding: 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  z-index: 10;
}

.scroll {
  position: absolute;
  top: 0;
  width: 100vw;
  padding: 0.5rem 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.875);
  animation: appear 0.3s ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.hamburger {
  display: none;
}

.links {
  display: flex;
  gap: 5rem;
  align-items: center;
}
.img {
  width: 5rem;
  transition: 1s;
}
.img:hover {
  transform: rotate(360deg);
  transition: 1s;
}

.link {
  text-decoration: none;
  font-weight: bold;
  transition: 0.5s;
}

.link:hover {
  color: #ff8e25;
  transform: translateY(-0.5rem);
  transition: 0.5s;
}

@media (max-width: 1440px) {
  .nav {
    position: relative;

    width: 100vw;
    padding: 1.5rem 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    z-index: 10;
  }
}

@media (max-width: 1050px) {
  .nav {
    position: relative;
    top: 0;
    width: 100vw;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    z-index: 5;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    z-index: 100;
  }
  .img {
    width: 4rem;
    transition: 1s;
  }

  .opened-hamburger1 {
    width: 55px;
    height: 5px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
    transform: rotate(40deg);
  }

  .opened-hamburger2 {
    display: none;
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
  }

  .opened-hamburger3 {
    width: 50px;
    height: 5px;
    background-color: black;
    margin: 5px;
    transition: 0.3s;
    transform: rotate(-55deg);
  }

  .line {
    width: 30px;
    height: 3px;
    background-color: aliceblue;
    margin: 5px;
    transition: 0.3s;
  }

  .link {
    color: black;
    font-size: 2.5rem;
  }

  .link:last-child {
    color: black;
    font-size: 2.5rem;
  }

  .link:hover {
    transform: translateX(-1rem);
    transition: 0.3s;
    color: white;
    cursor: pointer;
  }

  .links {
    position: absolute;
    left: 0rem;
    top: -3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    background-color: black;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 0.5s ease-out;
  }

  .open {
    position: absolute;
    left: 0rem;
    top: 0rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: rgba(255, 142, 37, 1);
    height: 100vh;
    width: 100vw;
    pointer-events: all;
    clip-path: circle(1300px at 90% -10%);
    -webkit-clip-path: circle(1300px at 90% -10%);
    transition: all 0.5s ease-out;
  }
}
