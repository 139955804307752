.services {
  width: 65%;
  padding-top: 10rem;
  margin: 5rem auto;
}

.title {
  font-size: 4.5rem;
  color: #ff8e25;
  text-align: center;
  margin-bottom: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  /* /* border-bottom: 3px solid #ff8e25; */
}

.service-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  text-align: center;
  margin-bottom: 18rem;
}

.service-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.service-header img {
  width: 3rem;
}

.service-header h2 {
  font-size: 3rem;
  color: white;
  border-bottom: 3px solid white;
  margin-bottom: 1rem;
}

.service-container p {
  font-size: 1.5rem;
  line-height: 1.6;
  background-color: #ff8e25;
  padding: 2rem 1.5rem;
  color: black;
  font-weight: 500;
  border-radius: 30px;
}

@media (max-width: 1440px) {
  .services {
    width: 70%;
    padding-top: 10rem;
    margin: 5rem auto;
  }

  .title {
    font-size: 4rem;
    color: #ff8e25;
    text-align: center;
    margin-bottom: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    /* /* border-bottom: 3px solid #ff8e25; */
  }

  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin-bottom: 18rem;
  }

  .service-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .service-header img {
    width: 3rem;
  }

  .service-header h2 {
    font-size: 3rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 1.5rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 2rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

@media (max-width: 1253px) {
  .title {
    font-size: 3rem;
  }

  .service-header img {
    width: 3rem;
  }

  .service-header h2 {
    font-size: 2.5rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 1.5rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 2rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

@media (max-width: 1094px) {
  .title {
    font-size: 3rem;
  }

  .service-header img {
    width: 2.5rem;
  }

  .service-header h2 {
    font-size: 2rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 1.3rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 2rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

@media (max-width: 765px) {
  .title {
    font-size: 2.5rem;
    margin-bottom: 6rem;
  }

  .service-header img {
    width: 2.5rem;
  }

  .service-header h2 {
    font-size: 2rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 1.1rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 2rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

@media (max-width: 589px) {
  .services {
    width: 80%;
    margin: 2rem auto;
    padding-top: 0rem;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 6rem;
  }

  .service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin-bottom: 7rem;
  }

  .service-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .service-header img {
    width: 2rem;
  }

  .service-header h2 {
    font-size: 1.5rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 1rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 1.5rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

@media (max-width: 497px) {
  .services {
    width: 90%;
    margin: 5rem auto;
  }
  .title {
    font-size: 1.5rem;
    margin-bottom: 6rem;
    padding: 0 1rem;

    gap: 0.4rem;
  }

  .service-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .service-header img {
    width: 1.5rem;
  }

  .service-header h2 {
    font-size: 1.3rem;
    color: white;
    border-bottom: 3px solid white;
    margin-bottom: 1rem;
  }

  .service-container p {
    font-size: 0.9rem;
    line-height: 1.6;
    background-color: #ff8e25;
    padding: 1.5rem 1.5rem;
    color: black;
    font-weight: 500;
    border-radius: 30px;
  }
}

/* @media (max-width: 497px) {
  .services {
    width: 90%;
    margin: 5rem auto;
  }
  .title {
    font-size: 1.2rem;
    margin-bottom: 6rem;
    padding: 0 1rem;
  }
} */
