.container {
  padding: 0rem 2rem 0rem 5rem;
  height: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 39rem;
}

.info {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-left: 1rem;
}

.info h2 {
  font-size: 4rem;
  color: #ff8e25;
}

.info p {
  font-size: 1.8rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.74);
}

.about-img {
  position: absolute;
  top: -16rem;
  right: 0;
  width: 450px;
  height: 550px;

  border-radius: 30px;
}

.flex-blocker {
  position: relative;
}

@media (max-width: 1440px) {
  .container {
    padding: 0rem 2rem 0rem 5rem;
    height: 800px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 35rem;
  }

  .info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-left: 1rem;
  }

  .info h2 {
    font-size: 3.5rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 1.5rem;
    line-height: 1.6;
  }

  .about-img {
    position: absolute;
    top: -16rem;
    right: 0;
    width: 400px;
    height: 500px;

    border-radius: 30px;
  }

  .flex-blocker {
    position: relative;
  }
}

@media (max-width: 1151px) {
  .container {
    padding: 0rem 2rem 0rem 5rem;
    height: 800px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 27rem;
  }

  .info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-left: 1rem;
  }

  .info h2 {
    font-size: 3rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 1.2rem;
    line-height: 1.6;
  }

  .about-img {
    position: absolute;
    top: -15rem;
    right: 0;
    width: 350px;
    height: 450px;

    border-radius: 30px;
  }

  .flex-blocker {
    position: relative;
  }
}

@media (max-width: 957px) {
  .container {
    padding: 0rem 2rem 0rem 4rem;
    height: 700px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24rem;
  }

  .info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-left: 1rem;
  }

  .info h2 {
    font-size: 2.7rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .about-img {
    position: absolute;
    top: -13rem;
    right: 0;
    width: 300px;
    height: 400px;

    border-radius: 30px;
  }

  .flex-blocker {
    position: relative;
  }
}

@media (max-width: 867px) {
  .container {
    padding: 1rem;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24rem;
  }

  .info {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    margin-left: 0rem;
    text-align: center;
  }

  .info h2 {
    font-size: 2.7rem;
    color: #ff8e25;
  }

  .info p {
    /* background-color: #ff8e25; */
    font-size: 1.1rem;
    line-height: 1.6;
    /* color: black; */
    color: rgba(255, 255, 255, 0.74);

    padding: 1.5rem;
    border-radius: 20px;
  }

  .about-img {
    display: none;
  }

  .flex-blocker {
    display: none;
  }
}

@media (max-width: 683px) {
  .container {
    padding: 1rem;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    /* margin-left: 1rem; */
  }

  .info h2 {
    font-size: 2.5rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 1rem;
    line-height: 1.6;
  }
}

@media (max-width: 472px) {
  .container {
    padding: 1.5rem;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    /* margin-left: 1rem; */
    text-align: center;
  }

  .info h2 {
    font-size: 2rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

@media (max-width: 375px) {
  .container {
    padding: 1rem;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    /* margin-left: 1rem; */
    text-align: center;
  }

  .info h2 {
    font-size: 1.9rem;
    color: #ff8e25;
  }

  .info p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}
