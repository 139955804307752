* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Slab", serif;
  color: white;
  scroll-behavior: smooth;
}

html,
body {
  overflow: scroll;
  max-width: 1990px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.875);
  /* background-image: linear-gradient(
      rgba(34, 34, 34, 0.65),
      rgba(34, 34, 34, 0.65)
    ),
    url("./Images/background2.png"); */
  background-size: cover;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

.container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.section {
  scroll-snap-align: start;
}
