.container {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  text-decoration: none;
  padding: 1.5rem;

  transition: 0.3s;
}

.icon {
  width: 3.5rem;
  text-align: center;
}

.info {
  font-size: 2.2rem;
  text-align: center;
  font-weight: bolder;
  transition: 0.3s;
}

.info:hover {
  cursor: pointer;
  color: #ff8e25;
  transition: 0.3s;
}

@media (max-width: 1440px) {
  .container {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    text-decoration: none;
    padding: 1.5rem;

    transition: 0.3s;
  }

  .icon {
    width: 3rem;
    text-align: center;
  }

  .info {
    font-size: 1.6rem;
    text-align: center;
    font-weight: bolder;
    transition: 0.3s;
  }

  .info:hover {
    cursor: pointer;
    color: #ff8e25;
    transition: 0.3s;
  }
}

@media (max-width: 1158px) {
  .container {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    text-decoration: none;
    padding: 1.5rem;

    transition: 0.3s;
  }

  .icon {
    width: 2.5rem;
    text-align: center;
  }

  .info {
    font-size: 1.5rem;
    text-align: center;
    font-weight: bolder;
  }
}

@media (max-width: 850px) {
  .container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    text-decoration: none;
    padding: 1.5rem 0 1.5rem 1.5rem;

    transition: 0.3s;
  }

  .icon {
    width: 2.5rem;
    text-align: center;
  }

  .info {
    font-size: 1.3rem;
    text-align: center;
    font-weight: bolder;
  }
}

@media (max-width: 750px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    padding: 1rem 1.5rem;
    transition: 0.3s;
  }

  .container:hover {
    cursor: pointer;
    color: #ff8e25;
    transition: 0.3s;
  }

  .icon {
    width: 2rem;
    text-align: center;
  }

  .info {
    font-size: 1.3rem;
    text-align: center;
    font-weight: bolder;
  }
}
