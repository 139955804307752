.container {
  width: 100%;
  margin: 8rem auto 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0rem;
  margin: 3rem 0;
}

.title {
  text-align: center;
  font-size: 3.5rem;
  color: #ff8e25;
}

.subtitle {
  margin-top: 1rem;
  text-align: center;
  font-size: 2.2rem;
}

.information {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.map {
  padding: 0 2rem;
}

@media (max-width: 1440px) {
  .container {
    width: 100%;
    margin: 8rem auto 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    padding: 2rem 0rem 2rem 3rem;
    margin: 3rem 0;
  }

  .title {
    text-align: center;
    font-size: 3.2rem;
    color: #ff8e25;
  }

  .subtitle {
    margin-top: 1rem;
    text-align: center;
    font-size: 2rem;
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .map {
    padding: 0 2rem;
  }
}

@media (max-width: 1000px) {
  .title {
    text-align: center;
    font-size: 2.8rem;
    color: #ff8e25;
  }

  .subtitle {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.8rem;
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

@media (max-width: 750px) {
  .container {
    width: 100%;
    margin: 10rem auto 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0rem;
    margin: 1rem 0;
  }

  .title {
    text-align: center;
    font-size: 2.2rem;
    color: #ff8e25;
  }

  .subtitle {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.5rem;
  }

  .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    padding: 0px;
  }

  .map {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 526px) {
  .container {
    width: 100%;
    margin: 12rem auto 0rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0rem;
    margin: 1rem 0;
  }

  .title {
    text-align: center;
    font-size: 2rem;
    color: #ff8e25;
  }

  .subtitle {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.5rem;
  }

  .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    padding: 0px;
  }

  .map {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 396px) {
  .container {
    width: 100%;
    margin: 12rem auto 0rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0rem;
    margin: 1rem 0;
  }

  .title {
    text-align: center;
    font-size: 1.5rem;
    color: #ff8e25;
  }

  .subtitle {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
  }

  .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    padding: 0px;
  }

  .map {
    padding-bottom: 1.5rem;
  }
}
